export const teamListData = [
    {
        id: 1,
        name: "João Kalandula",
        position: "Senior Real Estate Agent",
        image: "/img/team/team3.jpg",
        facebook: "https://facebook.com",
        twitter: "https://twitter.com",
        instagram: "https://instagram.com",
        linkedin: "https://linkedin.com",
        properties: [1,3,5]
    },
    {
        id: 2,
        name: "Manuel Lutangu",
        position: "Real Estate Agent",
        image: "/img/team/team2.jpg",
        facebook: "https://facebook.com",
        twitter: "https://twitter.com",
        instagram: "https://instagram.com",
        linkedin: "https://linkedin.com",
        properties: [2,4,6]
    },
    {
        id: 3,
        name: "Joaquim Mucungui",
        position: "Jr. Real Estate Agent",
        image: "/img/team/team1.jpg",
        facebook: "https://facebook.com",
        twitter: "https://twitter.com",
        instagram: "https://instagram.com",
        linkedin: "https://linkedin.com",
        properties: [6]
    }
]