import React from "react"

const Contact = () => {
    return (
        <section className="contact">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Contact</h1>
                            <h2 className="page-description">Contact</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-envelope"></i>
                                        <h5>Mail</h5>
                                        <h6>info@imowelwitschia.com</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h5>Address</h5>
                                        <h6>R. 14 de Abril, Namibe</h6>
                                        <h6>Angola</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-phone-alt"></i>
                                        <h5>Phone</h5>
                                        <h6>+244929213531</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                        {/* <form ref={form} onSubmit={sendEmail}> */}
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <label>Name</label>
                                    <input type="text" name="user_name" className="inp-contact" />
                                </div>
                                <div className="col-lg-6">
                                    <label>Phone</label>
                                    <input type="text" name="user_phone" className="inp-contact" />
                                </div>
                                <div className="col-lg-12">
                                    <label>Subject</label>
                                    <input type="text" name="user_subject" className="inp-contact" />
                                </div>
                                <div className="col-lg-12">
                                    <label>Message</label>
                                    <textarea name="message" className="ta-contact" rows="4"></textarea>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn-contact">Send Message</button>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact