export const flatListData = [
    {
        id: 1,
        slug: "luxurious-apartment-namibe",
        title: "Luxurious Apartment in Namibe Province",
        price: 3000,
        features: ["Air conditioning", "Wi-Fi", "Cable TV"],
        location: "Namibe City, Angola",
        description: "This luxurious apartment offers stunning views and modern amenities, perfect for a comfortable stay.",
        coverImage: "/img/imo/1/cover.jpg",
        images: [
            { original: '/img/imo/1/cover.jpg', thumbnail: '/img/imo/1/cover.jpg' },
            { original: '/img/imo/1/kitchen.jpg', thumbnail: '/img/imo/1/kitchen.jpg' },
            { original: '/img/imo/1/living_room.jpg', thumbnail: '/img/imo/1/living_room.jpg' },
            { original: '/img/imo/1/living_room2.jpg', thumbnail: '/img/imo/1/living_room2.jpg' },
            { original: '/img/imo/1/bathroom2.jpg', thumbnail: '/img/imo/1/bathroom2.jpg' },
            { original: '/img/imo/1/bath_room.jpg', thumbnail: '/img/imo/1/bath_room.jpg' },
            { original: '/img/imo/1/kitchen2.jpg', thumbnail: '/img/imo/1/kitchen2.jpg' },
            { original: '/img/imo/1/pool_view.jpg', thumbnail: '/img/imo/1/pool_view.jpg' },
            { original: '/img/imo/1/room1.jpg', thumbnail: '/img/imo/1/room1.jpg' },
            { original: '/img/imo/1/room2.jpg', thumbnail: '/img/imo/1/room2.jpg' },
            { original: '/img/imo/1/room3.jpg', thumbnail: '/img/imo/1/room3.jpg' },
            { original: '/img/imo/1/room4.jpg', thumbnail: '/img/imo/1/room4.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 5,
            bathrooms: 2,
            bedrooms: 3,
            area: "1500 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    {
        id: 2,
        slug: "spacious-apartment-namibe",
        title: "Spacious Apartment in Namibe Province",
        price: 2000,
        features: ["Fully furnished", "Balcony", "Elevator"],
        location: "Namibe City, Angola",
        coverImage: "/img/imo/2/cover.jpg",
        description: "A cozy studio perfect for singles or couples, featuring modern furnishings and a lovely balcony.",
        images: [   
            { original: '/img/imo/2/cover.jpg', thumbnail: '/img/imo/2/cover.jpg' },
            { original: '/img/imo/2/front.jpg', thumbnail: '/img/imo/2/front.jpg' },
            { original: '/img/imo/2/kitchen.jpg', thumbnail: '/img/imo/2/kitchen.jpg' },
            { original: '/img/imo/2/kitchen1.jpg', thumbnail: '/img/imo/2/kitchen1.jpg' },
            { original: '/img/imo/2/livingroom.jpg', thumbnail: '/img/imo/2/livingroom.jpg' },
            { original: '/img/imo/2/bathroom.jpg', thumbnail: '/img/imo/2/bathroom.jpg' },
            { original: '/img/imo/2/room2.jpg', thumbnail: '/img/imo/2/room2.jpg' },
            { original: '/img/imo/2/room3.jpg', thumbnail: '/img/imo/2/room3.jpg' },
            { original: '/img/imo/2/bathroom2.jpg', thumbnail: '/img/imo/2/bathroom2.jpg' },
            { original: '/img/imo/2/suite.jpg', thumbnail: '/img/imo/2/suite.jpg' },
            { original: '/img/imo/2/bathroom1.jpg', thumbnail: '/img/imo/2/bathroom1.jpg' },
            { original: '/img/imo/2/swimmingpool.jpg', thumbnail: '/img/imo/2/swimmingpool.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 1,
            bathrooms: 1,
            bedrooms: 1,
            area: "600 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    {
        id: 3,
        slug: "cozy-studio-namibe",
        title: "Cozy Studio in Namibe Province",
        price: 1500,
        features: ["Swimming pool", "Gym", "Parking"],
        location: "Namibe City, Angola",
        coverImage: "/img/imo/3/cover.jpg",
        description: "Enjoy spacious living with access to premium amenities, including a swimming pool and gym.",
        images: [
            { original: '/img/imo/3/cover.jpg', thumbnail: '/img/imo/3/cover.jpg' },
            { original: '/img/imo/3/backkitchen.jpg', thumbnail: '/img/imo/3/backkitchen.jpg' },
            { original: '/img/imo/3/kitchen.jpg', thumbnail: '/img/imo/3/kitchen.jpg' },
            { original: '/img/imo/3/livingroom.jpg', thumbnail: '/img/imo/3/livingroom.jpg' },
            { original: '/img/imo/3/livingroom2.jpg', thumbnail: '/img/imo/3/livingroom2.jpg' },
            { original: '/img/imo/3/room.jpg', thumbnail: '/img/imo/3/room.jpg' },
            { original: '/img/imo/3/shower.jpg', thumbnail: '/img/imo/3/shower.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 4,
            bathrooms: 2,
            bedrooms: 2,
            area: "1200 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    {
        id: 4,
        slug: "modern-apartment-namibe",
        title: "Modern Apartment in Namibe Province",
        price: 2500,
        features: ["Elevator", "24-hour security", "Laundry service"],
        location: "Namibe City, Angola",
        coverImage: "/img/imo/4/cover.jpg",
        description: "A modern apartment that offers comfort and security, ideal for families.",
        images: [
            { original: '/img/imo/4/cover.jpg', thumbnail: '/img/imo/4/cover.jpg' },
            { original: '/img/imo/4/kitchen.jpg', thumbnail: '/img/imo/4/kitchen.jpg' },
            { original: '/img/imo/4/living.jpg', thumbnail: '/img/imo/4/living.jpg' },
            { original: '/img/imo/4/livingroom.jpg', thumbnail: '/img/imo/4/livingroom.jpg' },
            { original: '/img/imo/4/room.jpg', thumbnail: '/img/imo/4/room.jpg' },
            { original: '/img/imo/4/sacada.jpg', thumbnail: '/img/imo/4/sacada.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 3,
            bathrooms: 1,
            bedrooms: 2,
            area: "1000 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    {
        id: 5,
        slug: "beautiful-apartment-namibe",
        title: "Beautiful Apartment in Namibe Province",
        price: 3000,
        features: ["Sea view", "Terrace", "Garden"],
        location: "Namibe City, Angola",
        coverImage: "/img/imo/5/cover.jpg",
        description: "A stunning apartment with breathtaking sea views, perfect for relaxing and entertaining.",
        images: [
            { original: '/img/imo/5/cover.jpg', thumbnail: '/img/imo/5/cover.jpg' },
            { original: '/img/imo/5/bathroom.jpg', thumbnail: '/img/imo/5/bathroom.jpg' },
            { original: '/img/imo/5/hall.jpg', thumbnail: '/img/imo/5/hall.jpg' },
            { original: '/img/imo/5/kitchen.jpg', thumbnail: '/img/imo/5/kitchen.jpg' },
            { original: '/img/imo/5/livingkitchen.jpg', thumbnail: '/img/imo/5/livingkitchen.jpg' },
            { original: '/img/imo/5/piscina.jpg', thumbnail: '/img/imo/5/piscina.jpg' },
            { original: '/img/imo/5/room.jpg', thumbnail: '/img/imo/5/room.jpg' },
            { original: '/img/imo/5/room2.jpg', thumbnail: '/img/imo/5/room2.jpg' },
            { original: '/img/imo/5/sacada.jpg', thumbnail: '/img/imo/5/sacada.jpg' },
            { original: '/img/imo/5/shower.jpg', thumbnail: '/img/imo/5/shower.jpg' },
            { original: '/img/imo/5/varanda.jpg', thumbnail: '/img/imo/5/varanda.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 4,
            bathrooms: 2,
            bedrooms: 3,
            area: "1800 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    {
        id: 6,
        slug: "apartment-namibe",
        title: "Apartment in Namibe Province",
        price: 3000,
        features: ["Sea view", "Terrace", "Garden"],
        location: "Namibe City, Angola",
        coverImage: "/img/imo/6/cover.jpg",
        description: "AAA stunning apartment with breathtaking sea views, perfect for relaxing and entertaining.",
        images: [
            { original: '/img/imo/6/cover.jpg', thumbnail: '/img/imo/6/cover.jpg' },
            { original: '/img/imo/6/bathroom.jpg', thumbnail: '/img/imo/6/bathroom.jpg' },
            { original: '/img/imo/6/kitchen.jpg', thumbnail: '/img/imo/6/kitchen.jpg' },
            { original: '/img/imo/6/livingkitchen.jpg', thumbnail: '/img/imo/6/livingkitchen.jpg' },
            { original: '/img/imo/6/livingroom.jpg', thumbnail: '/img/imo/6/livingroom.jpg' },
            { original: '/img/imo/6/livingroom2.jpg', thumbnail: '/img/imo/6/livingroom2.jpg' },
            { original: '/img/imo/6/room1.jpg', thumbnail: '/img/imo/6/room1.jpg' },
            { original: '/img/imo/6/sacada.jpg', thumbnail: '/img/imo/6/sacada.jpg' },
            { original: '/img/imo/6/sacada2.jpg', thumbnail: '/img/imo/6/sacada2.jpg' },
        ],
        propertyDetails: {
            kitchen: 1,
            allRooms: 4,
            bathrooms: 2,
            bedrooms: 3,
            area: "1800 sq ft"
        },
        mapLocation: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7700.464391139174!2d12.145598141446232!3d-15.20045424144032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenida%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2snl!4v1729193170139!5m2!1spt-PT!2snl"
    },
    // Add more flats with similar structure if needed
];
