import React from "react"
import { Link } from "react-router-dom";

const FlatItem = ({ slug, coverImage, title, price, features }) => {
    return (
        <div className="text-center col-lg-4 col-12 col-md-6 ">
            <div className="item">
                <div className="item-image">
                    <img className="img-fluid" src={coverImage} alt="flat" />
                </div>
                <div className="item-description">
                    <div className="d-flex justify-content-between mb-3">
                        <span className="item-title">{title}</span>
                        <span className="item-price">${price}</span>
                    </div>
                    <div className="item-icon d-flex alig-items-center justify-content-between">
                        {features.slice(0, 2).map((feature, index) => (
                            <div key={index}>
                                <i className="fas fa-check-circle"></i> <span className="item-feature">{feature}</span>
                            </div>
                        ))}
                        <Link to={`/flat/${slug}`} className="item-title">
                            <button className="btn btn-detail">View</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlatItem