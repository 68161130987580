import React from "react"
import Title from "./Title"
import FlatItem from "./FlatItem"
import { flatListData } from "../data/flatListData"

const FlatList = () => {
    const title = {
        text: "Our Properties",
        description: "Explore our selection of properties"
    }
    return (
        <section className="section-all-re">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                {flatListData.map((flat) => (
                        <FlatItem key={flat.id} {...flat} />
                    ))}
                </div>
            </div>
        </section>
    )

}

export default FlatList;