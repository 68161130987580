import React from "react";
import {Link} from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import { flatListData } from "../data/flatListData";
import { teamListData } from "../data/teamListData";
import TeamItem from "./TeamItem";

const FlatDetail = () => {
    const { slug } = useParams();
    const flat = flatListData.find(f => f.slug === slug);

    if (!flat) {
        return <div><h1>Property not found</h1></div>;
    }
    const recentlyAdded = flatListData
        .filter(f => f.slug !== slug)
        .sort(() => 0.5 - Math.random())
        .slice(0, 4);

    const teamMember = teamListData.find(member => member.properties.includes(flat.id));
    
    return (
        <div className="flat-detail">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">{flat.title}</h1>
                            <h2 className="page-description">{flat.location}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="fd-top flat-detail-content">
                            <div>
                                <h3 className="flat-detail-title">{flat.title}</h3>
                                <p className="fd-address"> <i className="fas fa-map-marker-alt"></i>
                                {flat.location}</p>
                            </div>
                            <div>
                                <span className="fd-price">${flat.price}</span>
                            </div>
                        </div>
                        <ImageGallery flickThreshold={0.50} slideDuration={0} items={flat.images} showNav={false} showFullscreenButton={false} showPlayButton={false} />
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="fd-item">
                                    <h4>Description</h4>
                                    <p>{flat.description}</p>
                                </div>
                                <div className="fd-item fd-property-detail">
                                    <h4>Property Details</h4>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span>Kitchen: </span>
                                            <span>{flat.propertyDetails.kitchen}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>All Rooms: </span>
                                            <span>{flat.propertyDetails.allRooms || "N/A"}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Bathrooms: </span>
                                            <span>{flat.propertyDetails.bathrooms || "N/A"}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span>Bedrooms: </span>
                                            <span>{flat.propertyDetails.bedrooms || "N/A"}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Area: </span>
                                            <span>{flat.propertyDetails.area || "N/A"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="fd-item fd-features">
                                    <h4>Features</h4>
                                    <div className="row">
                                        {flat.features.map((feature, index) => (
                                            <div className="col-lg-4" key={index}>
                                                <i className="fa fa-check"></i>
                                                <span>{feature}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="fd-item">
                                    <h4>Maps</h4>
                                    <iframe 
                                        title="myFrame" 
                                        src={flat.mapLocation} 
                                        width="100%" 
                                        height="450" 
                                        loading="lazy">
                                    </iframe>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    {teamMember && (
                                        <div className="fd-sidebar-item fd-sidebar-team">
                                            <h4>Property Agent</h4>
                                            <TeamItem
                                             name={teamMember.name}
                                             position={teamMember.position}
                                             image={teamMember.image}
                                             facebook={teamMember.facebook}
                                             twitter={teamMember.twitter}
                                             instagram={teamMember.instagram}
                                             linkedin={teamMember.linkedin}
                                            />
                                        </div>
                                    )}
                                <div className="fd-sidebar-item">
                                    <h4>Recently Added</h4>
                                    {
                                        recentlyAdded.map((flat, index) => (
                                            <div className="recently-item" key={index}>
                                                <img src={flat.coverImage} alt="recently added" width="50px" />
                                                <Link to={`/flat/${flat.slug}`}>{flat.title}</Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlatDetail