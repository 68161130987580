import React from "react"
const About = () => {
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Sobre a ImoWelwitschia</h1>
                            <h2 className="page-description">Conectando você ao Namibe, Angola</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/welwi.jpg" alt="product" className="w-100" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-item">
                                <div className="title">
                                A ImovelTrader oferece serviços personalizados para estrangeiros
                            </div>
                                <div className="about-text">
                                A ImovelTrader é uma companhia imobiliária localizada no Namibe, Angola. Especializamos-nos em aluguéis para visitantes internacionais, oferecendo uma experiência única e personalizada. Com a nossa equipe dedicada, garantimos que sua estadia em Angola seja confortável e sem complicações, seja para turismo ou negócios.
                            </div>
                                <div className="about-features">
                                <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Hospedagem adaptada às suas necessidades</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Serviço de motorista disponível</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Suporte completo durante a sua viagem</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About