import React from "react"
import Title from "./Title"
import TeamItem from "./TeamItem"
import { teamListData } from "../data/teamListData"

const TeamList = () => {
    const title = {
        text: "Our Team",
        description: "Meet our experienced real estate professionals"
    }
    return (
        <section className="section-teams">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                {teamListData.map(member => (
                    <TeamItem key={member.id} {...member} />
                    ))}
                    {/* <TeamItem/>
                    <TeamItem/>
                    <TeamItem/> */}
                </div>
            </div>
        </section>
    )
}

export default TeamList;