import React from "react"
const TeamItem = ({ name, position, image, facebook, twitter, instagram, linkedin }) => {
    return (
        <div className="col-lg-4">
            <div className="team">
                <div className="team-img">
                    <img src={image} alt={name} />
                </div>
                <div className="team-info">
                    <h5 className="team-name">{name}</h5>
                    <h6 className="team-position">{position}</h6>
                    <div className="social-links">
                        {/* <div className="social-item"><i className="fab fa-facebook"></i></div>
                        <div className="social-item"><i className="fab fa-twitter"></i></div>
                        <div className="social-item"><i className="fab fa-instagram"></i></div>
                        <div className="social-item"><i className="fab fa-linkedin"></i></div> */}
                        <a href={facebook} className="social-item"><i className="fab fa-facebook"></i></a>
                        <a href={twitter} className="social-item"><i className="fab fa-twitter"></i></a>
                        <a href={instagram} className="social-item"><i className="fab fa-instagram"></i></a>
                        <a href={linkedin} className="social-item"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamItem