import React from "react"
import {Link} from "react-router-dom"

const Footer = () => {
    return (
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-6">
                        <i className="fas fa-home"></i>
                        <span className="footer-other-text d-block mt-3 mb-3">
                        ImoWelwitschia: Your trusted partner in Angolan real estate. We offer premium properties and expert guidance for your perfect home or investment.
                        </span>
                        <div className="footer-social">
                            <a href="https://facebook.com/imowelwitschia" target="_blank" rel="noopener noreferrer" className="footer-social-item"><i className="fab fa-facebook"></i></a>
                            <a href="https://twitter.com/imowelwitschia" target="_blank" rel="noopener noreferrer" className="footer-social-item"><i className="fab fa-twitter"></i></a>
                            <a href="https://instagram.com/imowelwitschia" target="_blank" rel="noopener noreferrer" className="footer-social-item"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <p className="footer-title">Menu</p>
                        <ul className="footer-ul">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <p className="footer-title">Featured Properties</p>
                        <ul className="footer-ul">
                            <li><Link to="/flat/luxurious-apartment-namibe">Luxury Apartment</Link></li>
                            <li><Link to="/flat/spacious-apartment-namibe">Spacious Apartment</Link></li>
                            <li><Link to="/flat/beautiful-apartment-namibe">Beautiful Apartment</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <p className="footer-title">Contact</p>
                        <ul className="footer-ul">
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-clock"></i></div> <span>08:00-18:00</span>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item" ><i className="fas fa-envelope"></i></div> <a href="mailto:info@imowelwitschia.com">info@imowelwitschia.com</a>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-map-marker-alt"></i></div> <a href="https://www.google.com/maps/search/?api=1&query=Rua+14+de+Abril,+Namibe,+Angola" target="_blank" rel="noopener noreferrer">R. 14 de Abril, Namibe</a>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-phone-alt"></i></div> <a href="https://wa.me/244929213531">+244929213531</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="footer-box">
                            <h5>Location</h5>
                            <iframe
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7700.595261566994!2d12.140723179249006!3d-15.196869947623313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ba3d96bddb21ba3%3A0xe62c54d6732abcb7!2sR.%2014%20de%20Abril%2C%20Namibe%2C%20Angola!5e0!3m2!1spt-PT!2sus!4v1729188791632!5m2!1spt-PT!2sus" 
                                width="100%" 
                                height="200" 
                                style={{border:0}} 
                                allowFullScreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer